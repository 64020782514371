<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            More information
          </h2>
        </b-link>

        <b-card-title class="mb-1" />
        <b-card-text class="mb-2" />

        <!-- form -->
        <b-form
          class="auth-register-form mt-2"
          @submit.prevent="updateUsers"
        >

          <!-- userId -->
          <b-form-group
            label="ID"
            label-for="userId"
          >
            <b-form-input
              id="userId"
              v-model="registData.userid"
              name="register-userId"
              disabled
            />
          </b-form-group>

          <!-- username -->
          <b-form-group
            label="Your Name"
            label-for="userName"
          >
            <b-form-input
              id="userName"
              v-model="registData.username"
              name="register-userName"
              placeholder="Enter your full name"
              maxlength="10"
            />
          </b-form-group>

          <!-- mobile -->
          <b-form-group
            label="Mobile"
            label-for="mobile"
          >
            <b-form-input
              id="mobile"
              v-model="registData.mobile"
              name="register-mobile"
              placeholder="Enter your mobile number (e.g., 0101234567)"
              maxlength="20"
              @input="handleMobileInput"
            />
            <small
              v-if="mobileError"
              class="text-danger"
            >{{ mobileError }}</small>
          </b-form-group>

          <!-- accountBank -->
          <b-form-group
            label="Account Bank"
            label-for="accountBank"
          >
            <b-form-input
              id="accountBank"
              v-model="registData.accountBank"
              name="register-accountBank"
              placeholder="Enter your your bank"
              maxlength="20"
            />
          </b-form-group>

          <!-- accountNumber -->
          <b-form-group
            label="Account Number"
            label-for="accountNumber"
          >
            <b-form-input
              id="accountNumber"
              v-model="registData.accountNumber"
              name="register-accountNumber"
              placeholder="Enter your account number (e.g., 12012341234)"
              maxlength="20"
              @input="handleAccountNumberInput"
            />
            <small
              v-if="accountNumberError"
              class="text-danger"
            >{{ accountNumberError }}</small>
          </b-form-group>

          <!-- outcomePassword -->
          <b-form-group
            label="Outcome Password"
            label-for="outcomePassword"
          >
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="outcomePassword"
                v-model="registData.outcomePassword"
                :type="passwordFieldType"
                class="form-control-merge"
                name="register-outcomePassword"
                placeholder="Set your outcome password"
                maxlength="10"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <!-- submit button -->
          <b-button
            variant="primary"
            block
            type="submit"
          >
            Regist
          </b-button>
        </b-form>
      </b-card>
    </div>
  </div>

</template>

<script>
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'
import { UPDATE_USERS_ADDITIONAL } from '@/store/auth/mutation'

const authStore = createNamespacedHelpers('authStore')

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      registData: {
        userid: null,
        username: null,
        mobile: null,
        accountBank: null,
        accountNumber: null,
        outcomePassword: null,
      },
      mobileError: '',
      accountNumberError: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    // this.registData.userid = this.$store.getters['authStore/user'].userid
    this.registData.userid = JSON.parse(localStorage.getItem('userData')).userid
    this.registData.site = JSON.parse(localStorage.getItem('userData')).site
    this.registData.status = 'await'
  },
  methods: {
    ...authStore.mapActions({
      $updateUsersAdditional: UPDATE_USERS_ADDITIONAL,
    }),

    updateUsers() {

      const errors = [this.mobileError, this.accountNumberError]

      // 필수 필드 목록을 배열로 정의
      const requiredFields = [
        this.registData.userid,
        this.registData.username,
        this.registData.mobile,
        this.registData.accountBank,
        this.registData.accountNumber,
        this.registData.outcomePassword,
      ]

      // 필수 필드 중 하나라도 빈 값('')이면 return
      if (requiredFields.some(field => field === '' || field === null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '모든 정보를 입력해야 합니다.',
            icon: 'AlertCircleIcon',
            variant: 'error',
          },
        })
        return
      }

      const cleanedData = { ...this.registData }

      // 모든 필드에서 공백 제거
      Object.keys(cleanedData).forEach(key => {
        if (typeof cleanedData[key] === 'string') {
          cleanedData[key] = cleanedData[key].replace(/\s+/g, '')
        }
      })

      if (errors.every(error => error === '')) {
        this.$updateUsersAdditional(cleanedData)
          .then(response => {
            if (response !== undefined) {
              Swal.fire({
                title: '추가정보를 제출하였습니다. \n 최종승인 후 접속 가능합니다.',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: '확인',
                background: 'rgb(3,11,37)',
                customClass: {
                  confirmButton: 'btn btn-primary mr-1',
                },
              }).then(result => {
                if (result.isConfirmed) {
                  this.$router.push({ name: 'login' })
                }
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failure',
                  icon: 'EditIcon',
                  variant: 'error',
                },
              })
            }
          })
      } else {
        // errors 배열에 빈 문자열이 아닌 항목이 있을 경우에 실행할 코드 (옵션)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '올바른 정보를 입력해 주세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
      // }
    // })
    },

    handleMobileInput() {
      const { mobile } = this.registData

      // 모바일 번호가 입력되지 않은 경우
      if (!mobile) {
        this.mobileError = '모바일 번호를 입력해주세요.'
      } else if (/\D/.test(mobile)) {
        this.mobileError = '모바일 번호는 숫자만 포함해야 합니다.'
      } else {
        this.mobileError = ''
      }
    },

    handleAccountNumberInput() {
      const { accountNumber } = this.registData
      if (!accountNumber) {
        this.accountNumberError = '계좌 번호를 입력해주세요.'
      } else if (/\D/.test(accountNumber)) {
        this.accountNumberError = '계좌 번호는 숫자만 포함해야 합니다.'
      } else {
        this.accountNumberError = ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
